<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <b-row>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <label class="input-label form-label"
                >{{ $t("Upper Jaw Template") }}
              </label>
              <div v-if="$can(`${$route.meta.permission}-file.save`)" class="">
                <input
                  type="file"
                  @change="addFile($event, 'upper')"
                  accept=".stl, .obj"
                />
              </div>
              <div v-if="upperJawTemplate" class="mt-1">
                <b-button
                  variant="primary"
                  @click="handlePreviewClick('upper')"
                >
                  <span>{{ $t("Preview Upper Jaw") }}</span>
                </b-button>
              </div>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <label class="input-label form-label"
                >{{ $t("Lower Jaw Template") }}
              </label>
              <div v-if="$can(`${$route.meta.permission}-file.save`)" class="">
                <input
                  type="file"
                  @change="addFile($event, 'lower')"
                  accept=".stl, .obj"
                />
              </div>
              <div v-if="lowerJawTemplate" class="mt-1">
                <b-button
                  variant="primary"
                  @click="handlePreviewClick('lower')"
                >
                  <span>{{ $t("Preview Lower Jaw") }}</span>
                </b-button>
              </div>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Default Upper Tooth Costs"
                rules="required"
              >
                <TextInput
                  v-model="form.costsPerUpperJaw"
                  type="number"
                  :label="$t('Default Upper Tooth Costs')"
                  :tooltipMessage="$t('Default Upper Tooth Costs')"
                  :required="true"
                  :disabled="!$can(`${$route.meta.permission}.save`)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Default Lower Tooth Costs"
                rules="required"
              >
                <TextInput
                  v-model="form.costsPerLowerJaw"
                  type="number"
                  :label="$t('Default Lower Tooth Costs')"
                  :tooltipMessage="$t('Default Lower Tooth Costs')"
                  :required="true"
                  :disabled="!$can(`${$route.meta.permission}.save`)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Recalculation Factor"
                rules="required"
              >
                <TextInput
                  v-model="form.recalculationFactor"
                  type="range"
                  :label="$t('Recalculation Factor')"
                  :tooltipMessage="$t('Recalculation Factor')"
                  :required="true"
                  :min="'0'"
                  :max="'1'"
                  :step="'0.01'"
                  :disabled="!$can(`${$route.meta.permission}.save`)"
                />
                <span>{{ form.recalculationFactor }}</span>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Maxium Overall Costs"
                rules="required"
              >
                <TextInput
                  v-model="form.maximumOverallCosts"
                  type="number"
                  :label="$t('Maxium Overall Costs')"
                  :tooltipMessage="$t('Maxium Overall Costs')"
                  :required="true"
                  :disabled="!$can(`${$route.meta.permission}.save`)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="primary"
          @click="save"
          class="d-flex align-items-center gap-1"
          :class="{ 'cursor-not-allowed': isFormInvalid }"
          v-if="$can(`${$route.meta.permission}.save`)"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Save Configuration") }}</span>
        </b-button>
      </div>
    </validation-observer>
    <div v-if="$can('backoffice-case-cost.list')" class="card mt-3">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <h3>{{ $t("Tooth Costs Per Country") }}</h3>
        <button
          @click="openModal('add')"
          v-if="$can('backoffice-case-cost.create')"
          class="btn btn-primary d-flex align-items-center gap-1 md-mt-0 mt-1"
        >
          <feather-icon :icon="'PlusIcon'" size="12" />
          <span>{{ $t("Create Tooth Costs") }}</span>
        </button>
      </div>
      <div class="card-body">
        <div
          class="md-d-flex justify-content-between align-items-center mb-1 mt-1"
        >
          <div class="custom-search d-flex justify-content-between">
            <b-form-group>
              <div class="d-flex align-items-center">
                <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['25', '50', '100']"
                  class="ml-1"
                  @input="(value) => onPerPageChange({ pageLength: value })"
                />
              </div>
            </b-form-group>
          </div>
          <div class="md-mt-0 mt-2">
            <input
              autocomplete="off"
              type="text"
              name="search"
              class="form-control"
              :placeholder="$t('Search…')"
              v-model="search"
            />
          </div>
        </div>
        <!--==================================-->
        <!--==================================-->
        <div class="dextop-table-view">
          <div class="table-responsive api-keys-table">
            <!-- table -->
            <vue-good-table
              styleClass="vgt-table striped"
              :columns="columns"
              :fixed-header="false"
              :rows="rows"
              :search-options="{
                enabled: false,
                externalQuery: searchTerm,
                trigger: 'enter',
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->

                <span
                  class="d-flex align-items-center justify-content-center gap-2"
                  v-if="props.column.field === 'action'"
                >
                  <div
                    class="cursor-pointer"
                    @click="openModal('edit', props.row)"
                    v-if="$can('backoffice-case-cost.edit')"
                  >
                    <feather-icon size="16" icon="Edit2Icon" />
                  </div>
                  <div
                    v-if="$can('backoffice-case-cost.delete')"
                    class="cursor-pointer"
                    @click="destroy(props.row.id)"
                  >
                    <feather-icon size="16" icon="TrashIcon" />
                  </div>
                </span>
                <span
                  v-else-if="props.column.field === 'lowerJawCredits_numeric'"
                  style="white-space: nowrap"
                >
                  {{ props.row.lowerJawCredits }}
                </span>

                <span
                  v-else-if="props.column.field === 'upperJawCredits_numeric'"
                >
                  {{ props.row.upperJawCredits }}
                </span>
                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-end flex-wrap">
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="totalRecords"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => onPageChange({ currentPage: value })"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>

        <!--==================================-->
        <!--==================================-->
        <div class="reponsive-view-cards">
          <b-row>
            <b-col cols="12" lg="4" md="6" v-for="row in rows" :key="row.id">
              <div class="card">
                <div
                  class="card-header d-flex align-items-center justify-content-between"
                >
                  <h5>{{ $t("Country") }}: {{ row.country }}</h5>
                  <div class="d-flex gap-2">
                    <!-- Edit Button -->
                    <div
                      class="cursor-pointer"
                      v-if="$can('case-cost.edit')"
                      @click="openModal('edit', row)"
                      :title="$t('Edit')"
                    >
                      <feather-icon size="18" icon="Edit2Icon" />
                    </div>

                    <!-- Delete Button -->
                    <div
                      v-if="$can('case-cost.delete')"
                      class="cursor-pointer"
                      @click="destroy(row.id)"
                      :title="$t('Delete')"
                    >
                      <feather-icon size="18" icon="TrashIcon" />
                    </div>
                  </div>
                </div>

                <div class="card-body pt-0">
                  <p>
                    <strong>{{ $t("Lower Tooth Costs") }}: </strong>
                    {{ row.lowerJawCredits }}
                  </p>
                  <p>
                    <strong>{{ $t("Upper Tooth Costs") }}: </strong>
                    {{ row.upperJawCredits }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex justify-content-center mt-2">
                <b-pagination
                  :value="1"
                  :total-rows="totalRecords"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => onPageChange({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-col>
          </b-row>
        </div>
        <!--==================================-->
        <!--==================================-->
      </div>
    </div>
    <div class="c-modal" v-show="showPreviewModal">
      <div class="c-modal-content">
        <div class="c-modal-header">
          <h3>
            {{
              previewJawType === "upper"
                ? $t("Upper Jaw Preview")
                : $t("Lower Jaw Preview")
            }}
          </h3>
          <div class="x-icon" @click="showPreviewModal = false">
            <feather-icon size="16" icon="XIcon" />
          </div>
        </div>
        <div class="c-modal-body">
          <PreviewModel v-if="showPreviewModal" :jaw="showJaw" />
        </div>
      </div>
    </div>
    <b-modal
      id="edit-tag-modal"
      v-model="showModal"
      :title="
        modalType === 'add'
          ? $t('Create Tooth Costs Per Country')
          : $t('Edit Tooth Costs Per Country')
      "
      centered
      size="lg"
      hide-footer
    >
      <validation-observer ref="countryCreditRules">
        <b-row>
          <b-col cols="12" md="6">
            <div class="form-group">
              <label class="form-label input-label" for="country"
                ><span style="color: red">*</span>&nbsp;{{
                  $t("Country")
                }}</label
              >
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <multi-select
                  :multiple="false"
                  label="name"
                  track-by="name"
                  :options="
                    modalType === 'add' ? filteredCountries : editCountriesList
                  "
                  :key="countries"
                  v-model="costs.country"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              name="Upper Tooth Costs"
              rules="required"
            >
              <TextInput
                v-model="costs.upperJawCredits"
                type="number"
                :label="$t('Upper Tooth Costs')"
                :tooltipMessage="$t('Upper Tooth Costs')"
                :required="true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              name="Lower Tooth Costs"
              rules="required"
            >
              <TextInput
                v-model="costs.lowerJawCredits"
                type="number"
                :label="$t('Lower Tooth Costs')"
                :tooltipMessage="$t('Lower Tooth Costs')"
                :required="true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <div class="d-flex align-items-center justify-content-end mt-2">
          <b-button class="mr-1" @click="showModal = false" variant="primary"
            ><feather-icon :icon="'XIcon'" size="10" />{{
              $t("Cancel")
            }}</b-button
          >
          <b-button
            v-if="modalType === 'add'"
            @click="saveCountryCredits()"
            :class="{ 'cursor-not-allowed': isCountryCreditFormInvalid }"
            variant="primary"
            ><feather-icon :icon="'PlusIcon'" size="10" />{{
              $t("Create")
            }}</b-button
          >
          <b-button
            v-else
            @click="editCountryCredits()"
            :class="{ 'cursor-not-allowed': isCountryCreditFormInvalid }"
            variant="primary"
            ><feather-icon :icon="'PlusIcon'" size="10" />{{
              $t("Update")
            }}</b-button
          >
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import TextInput from "@/components/TextInput.vue";
import { mapGetters } from "vuex";
import countries from "@/assets/data/countries.json";
import MultiSelect from "vue-multiselect";
import { required } from "@validations";
import FileService from "@/services/file.service";
import PreviewModel from "./components/PreviewModel.vue";
import { debounce } from "@/utils/debounce";
export default {
  components: {
    PageHeader,
    ValidationProvider,
    ValidationObserver,
    TextInput,
    PreviewModel,
    MultiSelect,
  },
  data() {
    return {
      countries,
      form: {
        costsPerUpperJaw: "",
        costsPerLowerJaw: "",
        recalculationFactor: "0.5",
        maximumOverallCosts: "",
      },
      upperJawTemplate: "",
      lowerJawTemplate: "",
      showPreviewModal: false,
      showJaw: null,
      previewJawType: null,
      totalRecords: 0,
      pageLength: 25,
      page: 1,
      rows: [],
      selectedId: "",
      excludedCountries: [],
      filteredCountries: [],
      editCountriesList: [],
      search: "",
      showModal: false,
      searchTerm: "",
      sortBy: "createdAt",
      sortOrder: "desc",
      modalType: "add",
      costs: {
        country: "",
        upperJawCredits: "",
        lowerJawCredits: "",
      },
    };
  },
  computed: {
    ...mapGetters(["showLoader"]),
    ...mapGetters("globalConfiguration", ["configuration"]),
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Global Configuration"),
          active: true,
        },
      ];
    },
    isFormInvalid() {
      return (
        !this.form.costsPerUpperJaw ||
        !this.form.costsPerLowerJaw ||
        !this.form.recalculationFactor ||
        !this.form.maximumOverallCosts
      );
    },
    isCountryCreditFormInvalid() {
      return (
        !this.costs.country ||
        !this.costs.upperJawCredits ||
        !this.costs.lowerJawCredits
      );
    },
    columns() {
      return [
        {
          label: this.$t("Country"),
          field: "country",
        },
        {
          label: this.$t("Lower Tooth Costs"),
          field: "lowerJawCredits_numeric",
        },
        {
          label: this.$t("Upper Tooth Costs"),
          field: "upperJawCredits_numeric",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align",
          label: this.$t("Action"),
          field: "action",
          sortable: false,
        },
      ];
    },
  },
  watch: {
    search(...val) {
      this.debouncedFetch();
    },
  },
  async created() {
    this.debouncedFetch = debounce(async (newValue, oldValue) => {
      try {
        await this.loadItems();
      } catch (e) {
        console.error(e);
      }
    }, 300);
  },
  async mounted() {
    await this.refresh();
    this.loadItems();
  },
  methods: {
    handlePreviewClick(jawType) {
      if (jawType === "upper") {
        this.showJaw = this.upperJawTemplate;
        this.previewJawType = jawType;
      } else {
        this.showJaw = this.lowerJawTemplate;
        this.previewJawType = "lower";
      }
      this.showPreviewModal = true;
    },
    async refresh() {
      try {
        this.$store.commit("showLoader", true);
        await this.$store.dispatch("globalConfiguration/show");
        this.form.costsPerUpperJaw = this.configuration.costsPerUpperJaw ?? "";
        this.form.costsPerLowerJaw = this.configuration.costsPerLowerJaw ?? "";
        this.upperJawTemplate = this.configuration?.upperJawTemplate ?? "";
        this.lowerJawTemplate = this.configuration?.lowerJawTemplate ?? "";
        this.form.maximumOverallCosts =
          this.configuration?.maximumOverallCosts ?? "";
        this.form.recalculationFactor =
          this.configuration.recalculationFactor ?? 0.5;
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    onSortChange(params) {
      this.sortOrder = params[0].type;
      if (params[0].type == "none") this.sortOrder = "asc";

      this.sortBy = params[0].field;
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },
    openModal(type, item = null) {
      this.costs = {
        country: "",
        upperJawCredits: "",
        lowerJawCredits: "",
      };
      this.modalType = type;
      if (type == "edit") {
        this.selectedId = item.id;
        const matchedCountry = this.countries.find(
          (country) => country.name === item.country
        );
        this.editCountriesList = this.countries.filter(
          (country) =>
            country.name === item.country ||
            !this.excludedCountries.includes(country.name)
        );
        this.costs = {
          country: matchedCountry,
          upperJawCredits: item.upperJawCredits,
          lowerJawCredits: item.lowerJawCredits,
        };
      }
      this.showModal = true;
    },
    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.$store.commit("showLoader", true);
        let response = await this.$store.dispatch("countryCredits/list", {
          page: this.page,
          search: this.search,
          perPage: this.pageLength,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
        });
        this.rows = response?.data?.data;
        this.totalRecords = response?.data?.total;
        this.excludedCountries = response?.data?.countryNames;
        this.filteredCountries = this.countries.filter(
          (country) => !this.excludedCountries.includes(country.name)
        );
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    async destroy(id) {
      const confirmed = await this.showConfirmationDialog({
        title: this.$t("Do you want to delete this record?"),
        subText: this.$t("Yes delete it!"),
      });
      if (confirmed) {
        await this.$store.dispatch("countryCredits/destroy", id).finally(() => {
          this.loadItems();
        });
      }
    },
    addFile(event, jawType) {
      try {
        this.$store.commit("showLoader", true);
        const file = event.target.files[0];

        FileService.uploadFile(file, { visibility: 1 }, 0, 0.33)
          .then((response) => {
            this.$store.commit("showLoader", false);
            const jawTemplateKey =
              jawType === "upper" ? "upperJawTemplate" : "lowerJawTemplate";
            this.$store.dispatch("globalConfiguration/saveFiles", {
              [jawTemplateKey]: response.data[0].id,
              extension: response.data[0].type,
            });
          })
          .finally(() => {
            this.$store.commit("showLoader", false);
            this.refresh();
          });
      } catch (e) {}
    },
    async save() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          await this.$store.dispatch("globalConfiguration/save", {
            ...this.form,
          });
          await this.refresh();
        }
      });
    },
    async saveCountryCredits() {
      this.$refs.countryCreditRules.validate().then(async (success) => {
        if (success) {
          await this.$store
            .dispatch("countryCredits/create", {
              ...this.costs,
              country: this.costs.country?.name ?? "",
            })
            .then(async (res) => {
              this.showModal = false;
              this.costs = {
                country: "",
                upperJawCredits: "",
                lowerJawCredits: "",
              };
              this.loadItems();
            });
        }
      });
    },
    async editCountryCredits() {
      this.$refs.countryCreditRules.validate().then(async (success) => {
        if (success) {
          await this.$store
            .dispatch("countryCredits/update", {
              id: this.selectedId,
              data: {
                ...this.costs,
                country: this.costs.country?.name ?? "",
              },
            })
            .then(async (res) => {
              this.showModal = false;
              this.costs = {
                country: "",
                upperJawCredits: "",
                lowerJawCredits: "",
              };
              this.loadItems();
            });
        }
      });
    },
  },
};
</script>
